import React from 'react'

import { Button, Card, Spinner, Typography } from '@Azos-Seguros/apollo'
import analytics from '@azos/analytics'
import {
  ClickPolicyCoverageDetailsButtonsType,
  SimulateInsuranceClickSession,
  ViewPolicyCoverageDetailsType,
} from '@azos/analytics/src/domain/models'
import { Utils as ClientsUtils, useRemoteConfig } from '@azos/core'
import { EmptyState, withModal } from '@azos/shared'
import { CoveragesCode } from '@azos/shared/src/lib/coverages'
import { GetCoverageDetailsResponse } from '@data/models'
import { env } from '@main/config/env'
import { useRouter } from 'next/router'

import { PolicyCoverageDetailsProps } from './PolicyCoverageDetails.props'
import { Root } from './PolicyCoverageDetails.styles'
import { getEventCases } from './PolicyCoverageDetails.utils'

const PolicyCoverageDetails: React.VFC<PolicyCoverageDetailsProps> = ({
  code,
  getCoverageDetailsService,
  open,
  user,
  isGuardian,
  onClose,
  onRedirect,
  showDescription = false,
}) => {
  const remoteConfig = useRemoteConfig()
  const router = useRouter()

  const [data, setData] = React.useState<GetCoverageDetailsResponse | null>(
    null,
  )
  const [isLoading, setIsLoading] = React.useState(true)
  const [isErrorData, setIsErrorData] = React.useState(false)

  const fetchCoverageDetails = React.useCallback(
    (coverageCode: CoveragesCode) => {
      getCoverageDetailsService
        .execute({
          coverageCode,
        })
        .then(response => setData(response))
        .catch(() => setIsErrorData(true))
        .finally(() => setIsLoading(false))
    },
    [getCoverageDetailsService],
  )

  React.useEffect(() => {
    if (code) {
      fetchCoverageDetails(code)
    }
  }, [code, fetchCoverageDetails])

  const handleSimulate = () => {
    if (data?.title) {
      analytics.dashboard.policy.clickCoverageDetailsButtons.execute({
        type: ClickPolicyCoverageDetailsButtonsType.POLICY_DETAILS,
        coverage: data.title,
      })
    }

    if (code) {
      analytics.dashboard.home.simulateInsuranceClick.execute({
        sessao: SimulateInsuranceClickSession.PRODUCTS,
        tipo: getEventCases(code),
        isInsured: !!user?.isInsured,
        isGuardian,
        isNotInsuredNorGuardian: !user?.isInsured && !isGuardian,
      })
    }

    if (user?.isInsured && remoteConfig.get('B2C_UPSELL')) {
      router.push('/simular-novo-seguro')
    } else {
      router.push(env.SIMULATION_URL)
    }
  }

  const isDG = React.useMemo(
    () => !!code && ClientsUtils.coverages.isDGCoverage(code),
    [code],
  )

  const isError = React.useMemo(() => isErrorData || !code, [code, isErrorData])

  React.useEffect(() => {
    if (!isLoading && !isError && data) {
      analytics.dashboard.policy.viewCoverageDetails.execute({
        type: ViewPolicyCoverageDetailsType.POLICY_DETAILS,
        coverage: data.title,
      })
    }
  }, [data, isError, isLoading])

  return (
    <Root
      showHeader={false}
      noGutter
      open={open}
      variant="drawer"
      $isLoadingOrError={isLoading || isError}
      $backgroundUrl={data?.image}
      onClose={onClose}
    >
      {(isError || isLoading) && (
        <button
          className="policy-coverage-details__exit-button"
          onClick={onClose}
          data-testid="exit-modal-button"
        >
          <i className="icon-exit" />
        </button>
      )}

      {isError && (
        <div className="policy-coverage-details__empty-state-wrapper">
          <EmptyState
            className="policy-coverage-details__empty-state"
            align="center"
            icon="icon-policy-cancel"
            iconColor="error"
            title="Erro ao carregar o recurso"
          >
            Por favor, tente novamente ou entre em contato com nosso suporte.
          </EmptyState>
        </div>
      )}
      {isLoading && (
        <div className="policy-coverage-details__empty-state-wrapper">
          <EmptyState
            className="policy-coverage-details__empty-state"
            align="center"
            icon={<Spinner />}
            title="Carregando cobertura"
          >
            Estamos carregando os detalhes da cobertura.
          </EmptyState>
        </div>
      )}

      {data && (
        <div className="policy-coverage-details__wrapper">
          <div className="policy-coverage-details__header">
            <Typography
              variant="body2"
              className="policy-coverage-details__header--text"
            >
              Nossos produtos
            </Typography>
            <button
              className="policy-coverage-details__header--button"
              onClick={onClose}
              data-testid="exit-modal-button"
            >
              <i className="icon-exit" />
            </button>
          </div>
          <div className="policy-coverage-details__header--content">
            <i
              className={`${data?.iconName} policy-coverage-details__header--content-icon`}
            />
            <Typography
              variant="h4"
              className="policy-coverage-details__header--content-title"
              data-testid="policy-coverage-details__title"
            >
              {data.title}
            </Typography>
            <Typography
              variant="body3"
              className="policy-coverage-details__header--content-description"
            >
              {data.description}
            </Typography>
          </div>

          <div className="policy-coverage-details__content">
            {isDG && (
              <button
                className="policy-coverage-details__redirect"
                onClick={onRedirect}
              >
                <Typography
                  variant="bodyBold3"
                  className="policy-coverage-details__redirect-text"
                >
                  Conheça o novo DG10
                </Typography>
                <i className="icon-arrow-topright policy-coverage-details__redirect-icon" />
              </button>
            )}

            {data.productCards.map(productItem => (
              <Card
                key={productItem.order}
                className="policy-coverage-details__content--card"
              >
                <div className="policy-coverage-details__content--card-header">
                  <i
                    className={`${productItem.iconName} policy-coverage-details__content--card-icon`}
                  />
                  <Typography variant="bodyBold3">
                    {productItem.title}
                  </Typography>
                </div>
                {productItem.description && showDescription && (
                  <Typography
                    variant="caption"
                    className="policy-coverage-details__content--card-description"
                  >
                    {productItem.description}
                  </Typography>
                )}
              </Card>
            ))}
          </div>

          <div className="policy-coverage-details__footer">
            <Button fullWidth onClick={handleSimulate}>
              {data.buttonText ?? 'Simule um novo seguro'}
            </Button>
          </div>
        </div>
      )}
    </Root>
  )
}

export default withModal<PolicyCoverageDetailsProps>(PolicyCoverageDetails)
