import { SWRService, useFetch } from '@azos/core'
import { CoveragesCode } from '@azos/shared/src/lib/coverages'
import { CoverageModalUseCase } from '@domain/usecases'
import { makeCoverageModalService } from '@main/factories/services'

const coverageModalService = makeCoverageModalService()

export const useFetchCoverageDetails = (coverageCode: CoveragesCode) => {
  const { data = null, error } = useFetch<CoverageModalUseCase.Response>(
    new SWRService(coverageModalService.getUrl({ coverageCode }), () => {
      return coverageModalService.execute({ coverageCode })
    }),
    { revalidateOnFocus: false },
  )

  const isLoading = !data && !error

  return {
    data,
    isLoading,
    isError: error,
  }
}
