import React from 'react'

import { PageHeader, Spinner, Typography } from '@Azos-Seguros/apollo'
import analytics from '@azos/analytics'
import {
  ClickPolicyCoverageDetailsType,
  ViewAboutAzosType,
  ViewAzosContactType,
  ViewCoverageQuestionsType,
  ViewProductCoverageDetailsType,
} from '@azos/analytics/src/domain/models'
import { Utils as CoreUtils, makeStorageProvider } from '@azos/core'
import { EmptyState, useMediaQuery } from '@azos/shared'
import { CoveragesCode } from '@azos/shared/src/lib/coverages'
import { ListProductCardsUseCase } from '@domain/usecases'
import { MENU } from '@main/config/menu'
import { makeListProductCardsService } from '@main/factories/services'
import {
  CoverageItem,
  PolicyCoverageDetails,
} from '@presentation/components/molecules/Coverages'
import { CoverageQuestionDetails } from '@presentation/components/molecules/Coverages/CoverageQuestionDetails'
import {
  GuardianAboutModal,
  GuardiansModal,
} from '@presentation/components/molecules/Guardians'
import FirstAccessModal from '@presentation/components/molecules/PlgGuardian/FirstAccessModal/FirstAccessModal'
import { UserPhoto } from '@presentation/components/molecules/User'
import TemplateManager from '@presentation/components/templates/TemplateManager'
import { useRouter } from 'next/router'

import { AzosMediaCard } from './components'
import {
  AzosContactSlug,
  AzosContactType,
  AzosNotInsuredContact,
  ContentsSlug,
  CoverageQuestions,
} from './models'
import { NotInsuredHomeProps } from './notInsuredHome.props'
import { HeaderImage, Root } from './notInsuredHome.styles'

const listProductCardsService = makeListProductCardsService()
const storageProvider = makeStorageProvider()

const NotInsuredHome: React.FC<NotInsuredHomeProps> = ({
  content,
  isLoading,
  isGuardian,
  protectedList,
  user,
}) => {
  const { isMobile } = useMediaQuery()
  const router = useRouter()

  const [isUpdatePhotoOpen, setIsUpdatePhotoOpen] = React.useState(false)
  const [isFirstAccessOpen, setIsFirstAccessOpen] = React.useState(false)

  const [firstName] = CoreUtils.checkout.getNames(user?.displayName ?? '')

  const [productCards, setProductCards] =
    React.useState<ListProductCardsUseCase.Response | null>(null)
  const [selectedCode, setSelectedCode] = React.useState<CoveragesCode | null>(
    null,
  )
  const [coverageQuestionCode, setCoverageQuestionCode] =
    React.useState<CoverageQuestions | null>(null)

  const [isCoverageQuestionDetailsOpen, setIsCoverageQuestionDetailsOpen] =
    React.useState(false)
  const [isCoverageDetailsOpen, setIsCoverageDetailsOpen] =
    React.useState(false)
  const [isDG10DetailsOpen, setIsDG10DetailsOpen] = React.useState(false)

  const [isAzosMediaOpen, setIsAzosMediaOpen] = React.useState(false)
  const [isGuardianOpen, setIsGuardianOpen] = React.useState(false)
  const [isAboutGuardianOpen, setIsAboutGuardianOpen] = React.useState(false)

  const fetchListPolicyCards = React.useCallback(() => {
    listProductCardsService
      .execute()
      .then(response => setProductCards(response))
  }, [])

  React.useEffect(() => {
    const isFirstAccess =
      storageProvider.get('@azos/guardianFirstAccesss') !== 'true'

    if (isGuardian && !user?.isInsured && !isLoading && isFirstAccess) {
      setIsFirstAccessOpen(true)
    }
  }, [isGuardian, isLoading, user?.isInsured])

  React.useEffect(() => {
    fetchListPolicyCards()
  }, [fetchListPolicyCards])

  const handleCoverageClick = (code: CoveragesCode, label: string) => {
    setSelectedCode(code)
    analytics.dashboard.policy.clickCoverageDetails.execute({
      type: ClickPolicyCoverageDetailsType.HOME,
      coverage: label,
    })

    const coverageCases = {
      [CoveragesCode.COD_0002_ASSISTENCIA_FUNERAL]:
        ViewProductCoverageDetailsType.FUNERAL_ASSISTANCE,
      [CoveragesCode.COD_0004_DOENCAS_GRAVES]:
        ViewProductCoverageDetailsType.GENERAL_DISEASES,
      [CoveragesCode.COD_0005_DOENCAS_GRAVES_10]:
        ViewProductCoverageDetailsType.GENERAL_DISEASES_10,
      [CoveragesCode.COD_0003_INVALIDEZ]:
        ViewProductCoverageDetailsType.ACCIDENT_DISABILITY,
      [CoveragesCode.COD_0001_SEGURO_DE_VIDA]:
        ViewProductCoverageDetailsType.LIFE_INSURANCE,
      [CoveragesCode.COD_0006_DIH]: ViewProductCoverageDetailsType.DIH,
    }

    if (coverageCases[code]) {
      analytics.dashboard.home.viewProductCoverageDetails.execute({
        isInsured: !!user?.isInsured,
        tipo: coverageCases[code],
      })
    }

    setIsCoverageDetailsOpen(true)
  }

  const handleCoverageQuestionClick = (code: CoverageQuestions) => {
    setCoverageQuestionCode(code)

    const typeCases = {
      [CoverageQuestions.INSURANCE_PAYMENT]:
        ViewCoverageQuestionsType.INSURANCE_PAYMENT,
      [CoverageQuestions.COVERAGE_VALUE]:
        ViewCoverageQuestionsType.COVERAGE_VALUE,
      [CoverageQuestions.INSURANCE_CONTRACT]:
        ViewCoverageQuestionsType.INSURANCE_CONTRACT,
    }

    if (typeCases[code]) {
      analytics.dashboard.home.viewCoverageQuestions.execute({
        isInsured: !!user?.isInsured,
        tipo: typeCases[code],
      })
    }

    setIsCoverageQuestionDetailsOpen(true)
  }

  const handleContentRedirect = (slug: ContentsSlug) => {
    const typeCases: { [key in ContentsSlug]: ViewAboutAzosType } = {
      [ContentsSlug.ABOUT_INSURANCE]: ViewAboutAzosType.ABOUT_INSURANCE,
      [ContentsSlug.INSURANCE_IS_FOR_ME]: ViewAboutAzosType.INSURANCE_IS_FOR_ME,
      [ContentsSlug.OUR_DIFFERENTIAL]: ViewAboutAzosType.OUR_DIFFERENTIAL,
      [ContentsSlug.OUR_MISSION]: ViewAboutAzosType.OUR_MISSION,
      [ContentsSlug.ABOUT_GUARDIAN]: ViewAboutAzosType.ABOUT_GUARDIAN,
    }

    if (typeCases[slug]) {
      analytics.dashboard.home.viewAboutAzos.execute({
        isInsured: !!user?.isInsured,
        tipo: typeCases[slug],
      })
    }

    if (slug === ContentsSlug.ABOUT_GUARDIAN) setIsAboutGuardianOpen(true)
    else router.push(`/sobre-a-azos/${slug}`)
  }

  const handleContact = (contact: AzosNotInsuredContact) => {
    const typeCases: { [key in AzosContactType]: () => void } = {
      [AzosContactType.MODAL]: () => setIsAzosMediaOpen(true),
      [AzosContactType.LINK]: () => window.open(contact.link, '_blank'),
    }

    const eventTypeCases: { [key in AzosContactSlug]: ViewAzosContactType } = {
      [AzosContactSlug.AZOS_MEDIA]: ViewAzosContactType.AZOS_MEDIA,
      [AzosContactSlug.FACEBOOK]: ViewAzosContactType.FACEBOOK,
      [AzosContactSlug.INSTAGRAM]: ViewAzosContactType.INSTAGRAM,
      [AzosContactSlug.LINKEDIN]: ViewAzosContactType.LINKEDIN,
      [AzosContactSlug.YOUTUBE]: ViewAzosContactType.YOUTUBE,
    }

    if (eventTypeCases[contact.slug]) {
      analytics.dashboard.home.viewAzosContact.execute({
        isInsured: !!user?.isInsured,
        rede: eventTypeCases[contact.slug],
      })
    }

    if (typeCases[contact.type]) return typeCases[contact.type]()
  }

  return (
    <>
      <Root>
        <TemplateManager
          headerProps={{ user }}
          navigationProps={{ value: MENU.INICIO }}
        >
          {isLoading && (
            <div className="empty-state-wrapper">
              <EmptyState
                align="center"
                icon={<Spinner />}
                title="Carregando dados"
              >
                Estamos carregando as informações inciais.
              </EmptyState>
            </div>
          )}
          {!isLoading && (
            <>
              <PageHeader.Root className="not-insured-home__page-header-wrapper">
                <PageHeader.Content className="not-insured-home__page-header-content">
                  <div className="not-insured-home__user-content">
                    {!isMobile && (
                      <UserPhoto
                        className="not-insured-home__user-content--photo"
                        user={user}
                        isOpen={isUpdatePhotoOpen}
                        setIsOpen={setIsUpdatePhotoOpen}
                      />
                    )}
                    <div className="not-insured-home__user-content--text">
                      <Typography
                        variant="h4"
                        tag="span"
                        className="not-insured-home__user-content--user-name"
                      >
                        Olá, {firstName}!
                      </Typography>
                      <Typography
                        variant="body3"
                        className="not-insured-home__user-content--user-email"
                      >
                        {user?.email}
                      </Typography>
                    </div>
                  </div>

                  <div className="not-insured-home__header">
                    {content.header.map(item => (
                      <HeaderImage
                        key={item.slug}
                        $backgroundImage={item.imageUrl}
                        $backgroundColor={item.backgroundColor}
                        role="button"
                        className="not-insured-home__header--image"
                        onClick={() => handleContentRedirect(item.slug)}
                      >
                        <div className="not-insured-home__header--image-content">
                          <Typography variant="bodyBold">
                            {item.title}
                          </Typography>
                          <Typography variant="body3">
                            {item.description}
                          </Typography>
                        </div>
                        <div className="not-insured-home__header--image-footer">
                          <Typography className="text-link" variant="bodyBold3">
                            Veja como{' '}
                          </Typography>
                          <i className="icon-arrow-topright" />
                        </div>
                      </HeaderImage>
                    ))}
                  </div>
                </PageHeader.Content>
              </PageHeader.Root>

              <div className="not-insured-home__content">
                {content.aboutInsurance && (
                  <div className="not-insured-home__about-insurance">
                    <div className="not-insured-home__about-insurance-header">
                      <Typography variant="bodyBold3">
                        Tem dúvidas sobre
                      </Typography>
                      <Typography
                        variant="bodyBold3"
                        className="not-insured-home__text"
                      >
                        seguros
                      </Typography>
                      <Typography variant="bodyBold3">?</Typography>
                    </div>
                    <div className="not-insured-home__about-insurance-content">
                      {content.aboutInsurance.map(item => (
                        <div
                          key={item.slug}
                          className="not-insured-home__coverage-questions-content--item"
                          role="button"
                          onClick={() => handleContentRedirect(item.slug)}
                        >
                          <div className="not-insured-home__coverage-questions-content--header">
                            <i className={item.icon} />
                            <i className="icon-arrow-topright link" />
                          </div>
                          <Typography variant="bodyBold3">
                            {item.title}
                          </Typography>
                          <Typography variant="caption">
                            {item.description}
                          </Typography>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {productCards && (
                  <div className="not-insured-home__products">
                    <div className="not-insured-home__products-header">
                      <Typography variant="bodyBold3">Nossos</Typography>
                      <Typography
                        variant="bodyBold3"
                        className="not-insured-home__text"
                      >
                        {productCards.length} produtos
                      </Typography>
                    </div>
                    <div className="not-insured-home__products-content">
                      {productCards.map(coverage => (
                        <CoverageItem
                          className="not-insured-home__products-content--item"
                          key={coverage.productCode}
                          coverage={coverage}
                          showDescription
                          onCoverageClick={handleCoverageClick}
                        />
                      ))}
                    </div>
                  </div>
                )}

                <div className="not-insured-home__coverage-questions">
                  <div className="not-insured-home__coverage-questions-header">
                    <Typography variant="bodyBold3">
                      Como funcionam as
                    </Typography>
                    <Typography
                      variant="bodyBold3"
                      className="not-insured-home__text"
                    >
                      coberturas
                    </Typography>
                    <Typography variant="bodyBold3">?</Typography>
                  </div>
                  <div className="not-insured-home__coverage-questions-content">
                    {content.coverageQuestions.map(item => (
                      <div
                        key={item.id}
                        className="not-insured-home__coverage-questions-content--item"
                        role="button"
                        onClick={() => handleCoverageQuestionClick(item.id)}
                      >
                        <div className="not-insured-home__coverage-questions-content--header">
                          <i className={item.icon} />
                          <i className="icon-arrow-topright link" />
                        </div>
                        <Typography variant="bodyBold3">
                          {item.title}
                        </Typography>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="not-insured-home__about-azos">
                  <div className="not-insured-home__about-azos-header">
                    <Typography variant="bodyBold3">
                      O que a Azos faz
                    </Typography>
                    <Typography
                      variant="bodyBold3"
                      className="not-insured-home__text"
                    >
                      diferente
                    </Typography>
                    <Typography variant="bodyBold3">?</Typography>
                  </div>
                  <div className="not-insured-home__about-azos-content">
                    {content.aboutAzos.map(item => (
                      <div
                        key={item.slug}
                        className="not-insured-home__about-azos--item"
                        role="button"
                        onClick={() => handleContentRedirect(item.slug)}
                      >
                        <img
                          src={item.imageUrl}
                          alt={item.title}
                          loading="lazy"
                        />
                        <div className="not-insured-home__about-azos--item-wrapper">
                          <div className="not-insured-home__about-azos--item-content">
                            <i className={item.icon} />
                            <Typography
                              variant="bodyBold3"
                              className="not-insured-home__about-azos--item-title"
                            >
                              {item.title}
                            </Typography>
                            <Typography
                              variant="caption"
                              className="not-insured-home__about-azos--item-description"
                            >
                              {item.description}
                            </Typography>
                          </div>
                          <div className="not-insured-home__about-azos--item-link-content">
                            <Typography
                              variant="bodyBold3"
                              className="not-insured-home__about-azos--item-link-content--text"
                            >
                              {item.linkText}
                            </Typography>
                            <i className="icon-arrow-topright" />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="not-insured-home__azos-contact">
                  <div className="not-insured-home__azos-contact-header">
                    <Typography
                      variant="bodyBold3"
                      className="not-insured-home__text"
                    >
                      Siga
                    </Typography>
                    <Typography variant="bodyBold3">a Azos</Typography>
                  </div>
                  <div className="not-insured-home__azos-contact-content">
                    {content.azosContact.map(item => (
                      <div
                        key={item.title}
                        className="not-insured-home__azos-contact-content--item"
                        onClick={() => handleContact(item)}
                      >
                        <div className="not-insured-home__azos-contact-content--header">
                          <i className={item.icon} />
                          <i className="icon-arrow-topright link" />
                        </div>
                        <Typography variant="bodyBold3">
                          {item.title}
                        </Typography>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}
        </TemplateManager>
      </Root>
      <PolicyCoverageDetails
        code={selectedCode}
        open={isCoverageDetailsOpen}
        showDescription
        onClose={() => setIsCoverageDetailsOpen(false)}
        onRedirect={() => setIsDG10DetailsOpen(true)}
      />
      <CoverageQuestionDetails
        code={coverageQuestionCode}
        open={isCoverageQuestionDetailsOpen}
        onClose={() => setIsCoverageQuestionDetailsOpen(false)}
      />
      <PolicyCoverageDetails
        code={CoveragesCode.COD_0005_DOENCAS_GRAVES_10}
        open={isDG10DetailsOpen}
        showDescription
        onClose={() => setIsDG10DetailsOpen(false)}
      />
      <AzosMediaCard
        open={isAzosMediaOpen}
        onClose={() => setIsAzosMediaOpen(false)}
      />
      <FirstAccessModal
        open={isFirstAccessOpen}
        onClose={() => setIsFirstAccessOpen(false)}
        onViewAboutGuardian={() => setIsAboutGuardianOpen(true)}
        onViewProtected={() => setIsGuardianOpen(true)}
        protectedList={protectedList}
      />
      <GuardiansModal
        open={isGuardianOpen}
        onClose={() => setIsGuardianOpen(false)}
      />

      <GuardianAboutModal
        open={isAboutGuardianOpen}
        onClose={() => setIsAboutGuardianOpen(false)}
      />
    </>
  )
}

export default NotInsuredHome
